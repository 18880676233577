import React from "react"
import PropTypes from "prop-types"
import { PageRenderer, graphql } from "gatsby"
import Fade from 'react-reveal/Fade'
import he from 'he'

import Link from "../utils/link"

import Header from "../components/header"
import SEO from '../components/seo'

import "./service.scss"

function Template(props) {
  const service = props && props.data.wpService

  const building = typeof window === "undefined"
  const [setIndexPageData] = React.useState(!building && window.indexPageData)

  React.useEffect(() => {
    window.setIndexPageData = () => {
      if (window.indexPageData && typeof setIndexPageData !== "undefined" ) setIndexPageData(window.indexPageData)
    }

    if (document) {
      document.body.style.maxHeight = "100vh"
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.maxHeight = "unset"
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <>
      <PageRenderer key={"/"} location={{ pathname: "/" }} />
      <SEO title={he.decode(service.seo.title)} bodyClass='' description={service.seo.metaDesc} />
      <div className="overlay-page">
        <Header closeAndNavigateTo="/" />
        <div className="content">
          <div className="container">
            <Fade 
              bottom 
              distance='40px'
            >
              <div className="service">
                <div className="service__title">{service.title}</div>
                <div className="service__subtitle">{service.acf.subtitle}</div>
                <div
                  className="service__details"
                  dangerouslySetInnerHTML={{ __html: service.acf.content }}
                />
                <div className="service__contact-link">
                  <Link to="/contact">Contact Us</Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object,
}

export const serviceQuery = graphql`
  query($id: String!) {
    wpService(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        subtitle
        content
      }
    }
  }
`

export default Template
